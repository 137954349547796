.back-to-top{
    z-index: 9999;
}
@media screen and (max-width:1250px) {
    .container {
        max-width: 100%;
    }
    .tab-content{
        p{
            text-align: left !important;
        }
    }
    .contentASide .list-unstyled{
        margin-left: 0 !important;
    }
    
    .mobile-main-menu {
        max-width: 320px;
        right: -10px;
        left: unset;
    }
    .mainCategory{
        li{
            .item{
                height: 100%;
            }
            height: 280px !important;
            h3{
                line-height: normal;
            }
        }
    }
    .headerRow .pagesHeader .header-top-menu-level-1 {
        top: 100%;
    }

    .headerRow .pagesHeader .header-top-menu-level-1,
    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-3.active,
    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-2.active {
        border: none;
    }

    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-1.active {
        width: calc(100% / 3);
        overflow: unset;
        padding-bottom: 45px !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    .colored {
        color: #ffb300 !important;
    }

    .arrow-hm {
        transform: rotate(0deg);

        &::after,
        &::before {
            transition: all .3s ease-in-out;
        }
    }

    .arrow-hm.has-active {
        transform: rotate(0deg);

        &::after,
        &::before {
            background: #ffb300 !important;
        }
    }

    .headerRow .pagesHeader .header-top-menu-level-1 li {
        background-image: none;
        border-bottom: none;
    }

    .header-top-menu-level-1 .btn_box {
        display: block;
        margin: 0;
        margin-left: 10px;
        text-align: unset;
    }

    .headerRow .pagesHeader .header-top-menu-level-2>li:first-of-type {
        border-top: none;
    }

    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-3.active,
    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-2.active {
        padding: 10px;
        margin: 0;
        position: absolute;
        width: 280px;
        left: 100%;
        top: 0;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
        background-color: #F7F7F7;
        min-height: 100%;
    }

    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-2.active {
        background-color: #f7f7f7 !important;
        padding: 10px;
        width: 100%;
    }

    .headerRow .pagesHeader .header-top-menu-level-2 .arrow-hm {
        display: block;
    }

    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-3.active {
        width: 100%;
    }

    .plusesButtom .row {
        display: flex;
    }

    .itemsList {
        margin: 0;
    }

    .wow.pulse.animated .text-center {
        margin-top: 30px;
    }

    hr.line1 {
        margin: 0;
    }

}

.orange_link {
    font-size: 14px;
    text-decoration: underline;
    color: #ffb300;
}

.contacts_mobile {

    &>div {
        margin-bottom: 30px;
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        font-family: 'Roboto Condensed', Arial, sans-serif;
        color: #141106;
    }

    .descr {
        font-size: 14px;
        font-weight: 500;
        color: #949893;
    }

    .storage_address {
        .descr {
            line-height: 1.5;
        }

        a:last-child {
            margin-left: 50px;
        }
    }

    .mail {
        a:last-child {
            margin-left: 15px;
        }
    }

    .qr_code {
        margin-left: -10px;
    }

}

.contacts_table {
    margin-left: 0;
}

.sort_prod {
    display: none;
}

@media screen and (max-width:992px) {
    .deliveriesBlock h4 {
        display: flex;
    }
    .contentASide {
        display: none !important;
    }
    .contentRight {
        margin-top: 20px;
    }
    .contentRow{
        padding-top: 0;
    }
    .back-to-top{
        right: 20px;
    }
    .prod_info {
        display: flex;
        justify-content: space-between;

        .image_box {
            width: 54%;
            float: none;
            margin-bottom: 0;

            .image {
                height: 100%;

                img {
                    height: 100%;
                }
            }
        }

        .product_box {
            float: none;
            width: 44%;
        }
    }

    .sort_prod_list {
        opacity: 0;
        min-height: unset;
        height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
        z-index: 0;

        &.active {
            padding: 20px;
            margin-bottom: 20px;
            height: auto;
            opacity: 1;
            border-radius: 0 0 3px 3px;
        }
    }

    .sort_prod {
        display: flex;
        border-bottom: 1px dotted #d1d1d1;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            color: #141106;
            font-size: 15px;
            font-weight: 400;
        }

        .arrow {
            transition: ease all .3s;
            cursor: pointer;
            display: block;
            width: 20px;
            height: 20px;
            position: relative;
            transform: rotate(90deg);
        }

        .arrow::before {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            background: #141106;
            height: 1px;
            top: 8px;
            transform: rotate(45deg);
            left: 7px;
        }

        .arrow::after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            background: #141106;
            height: 1px;
            top: 12px;
            transform: rotate(-45deg);
            left: 7px;
        }

        &.active {
            .arrow {
                transform: rotate(-90deg);
            }
        }
    }

    .remove_padding {
        padding-left: 0;
        padding-right: 0;
    }

    .featuresProducts {
        margin-left: 0;
    }

    .itemsList {
        margin-bottom: 50px;

        &>li {
            width: 50%;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: #141106;
    }



    .footerRow {
        .row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            div:nth-child(1) {
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 400px;
                margin-bottom: 30px;

                .white-text {
                    margin-top: 0;
                }

                .inlineMenu:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;

                    li {
                        margin-bottom: 15px;
                    }

                    a {
                        border-right: none;
                        padding: 0;
                        margin: 0;
                        font-size: 15;
                    }
                }

                .inlineMenu:nth-child(4) {
                    display: block;
                    column-count: 2;
                    column-gap: 0;
                    padding-left: 0;

                    li {
                        display: block;
                        margin-bottom: 15px;

                        a {
                            border-right: none;
                            margin: 0;
                            padding: 0;
                            font-size: 15px;
                        }
                    }

                    br {
                        display: none;
                    }

                    h4 {
                        font: 700 16px/1.1 'Open Sans', Arial, Helvetica, sans-serif;
                        margin-top: 0;
                        margin-bottom: 20px;

                        &::before {
                            content: none;
                        }
                    }
                }
            }

            div:nth-child(3) {
                display: flex;
                flex-wrap: wrap;
                width: unset;
                max-width: 62%;

                .white-text {
                    width: 100%;
                    margin-bottom: 0;
                }

                .office_time,
                .storage_time {
                    width: 50%;
                }
            }

            div:nth-child(5) {
                max-width: 30%;
                margin-top: 35px;
            }
        }
    }

    .heading+.row.well.productsViewPagination {
        display: none;
    }

    .row.blog_news {
        display: flex;
        flex-wrap: wrap;

        .blogView {
            .media-heading {
                height: auto;
                margin-bottom: 10px;
            }

            .btn {
                margin-top: 10px;
            }
        }
    }
}

.desktop_hidden {
    display: none;
}

.hidden576 {
    pointer-events: none;
    visibility: hidden;
    transition: none;
}

.cart_total_goods {
    tbody {
        td,
        th {
            border-top: 1px solid #ddd;
        }
        .thick-line{
            border-top: 2px solid;
        }
    }
}

@media screen and (max-width:767px) {
    .mobile-search.active{
        background-image: url(../images/cross.svg) !important;
        background-size: 40%;
    }
    .to_column{
        display: flex;
        flex-direction: column;
        &>div{
            width: 100%;
            padding: 0 25px !important;
        }
    }
    .simplebar-track.simplebar-horizontal {
        background-color: #d6d6d6;
        height: 6px;
        border-radius: 3px;
        overflow: visible;
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        top: -2px;
    }

    .simplebar-scrollbar:before {
        background: #ffb300;
    }

    .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
        height: 10px;
        left: 0px;
        right: 0px;
    }
    .table-responsive{
        border: none;
    }
    .table-cart {
        border: none;
        overflow: hidden;

        tr,
        td {
            white-space: unset !important;
        }

        td,
        .cart_total_goods .text-right {
            text-align: left;
        }

        .hidden576 {
            display: none;
        }
    }

    .itemsList>li {
        width: 100%;
    }

    .desktop_hidden {
        display: block;

        &::before {
            content: none;
        }
    }

    .show_f_menu1,
    .show_f_menu2 {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 7px;
            background: #fff;
            height: 1px;
            top: 8px;
            transform: rotate(45deg);
            right: 7px;
            transition: .3s ease-in-out;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 7px;
            background: #fff;
            height: 1px;
            top: 12px;
            transform: rotate(-45deg);
            right: 7px;
            transition: .3s ease-in-out;
        }
    }

    .show_f_menu1.active,
    .show_f_menu2.active {
        &::after {
            top: 8px;
            right: 2px;
        }
    }

    .f_menu1.active {
        margin-bottom: 20px;
    }

    .f_menu1,
    .f_menu2 {
        overflow: hidden;
        height: 0;
        opacity: 0;
        transition: .3s ease-in-out;

        &.active {
            height: 100%;
            opacity: 1;
        }
    }

    .mobile_hidden {
        display: none;
    }

    .hidden-xs.overlay.bg1.plusesButtom {
        display: block !important;

        .list-inline.row {
            display: block;
        }
    }

    .hidden-xs {
        display: initial !important;
    }

    .footerRow {
        .row {
            div:nth-child(1) {
                max-height: unset;
                margin-bottom: 0;

                .inlineMenu.f_menu2 {
                    column-count: unset;
                }
            }

            div:nth-child(3) {
                max-width: 100%;
                width: 100%;

                .office_time,
                .storage_time {
                    width: 100%;
                }
            }

            div:nth-child(5) {
                max-width: 100%;
                width: 100%;
                margin-top: 0;
                font-size: 15px;
            }
        }

        .qrcode {
            display: flex;
            align-items: center;
        }
    }

    .divider.small {
        height: 0;
    }

    .flexslider.sliderTop {
        display: none;
    }

    .header-top-menu-level-1 .btn_box {
        margin-left: 40px;
    }

    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-1.active {
        width: 100%;
        padding: 20px 0px !important;
    }

    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-3.active,
    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-2.active {
        position: unset;
        box-shadow: none;
    }

    .headerRow .pagesHeader .header-top-menu>li .header-top-menu-level-1.active {
        overflow: auto;
        max-height: 80vh;
        border: none;

        li {
            a {
                margin-left: 30px;
            }

            .arrow-hm {
                margin-right: 30px;
            }
        }

    }

    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-2.active {
        padding: 0;

        li {
            a {
                margin-left: 40px;
            }
        }
    }

    .headerRow .pagesHeader .header-top-menu li .header-top-menu-level-3.active {
        background-color: #efefef;
        padding: 0;

        li {
            a {
                margin-left: 50px;
            }
        }
    }

    .arrow-hm.has-active {
        transform: rotate(90deg);
    }

    .headerRow .pagesHeader .header-top-menu-level-1 li a {
        font-weight: normal;
        color: #000 !important;
    }

    .headerRow .pagesHeader .header-top-menu-level-2>li>a,
    .headerRow .pagesHeader .header-top-menu-level-2>li>.el-hm a {
        color: #000 !important;
    }

    .headerRow .pagesHeader .header-top-menu-level-1 li a.colored {
        color: #ffb300 !important;
    }

    .contacts_mobile .storage_address a:last-child,
    .contacts_mobile .mail a:last-child {
        display: none;
    }
}

@media screen and (max-width:576px) {
    .productViewTab{
        .nav-tabs{
            display: block;
            border: none;
            &::after{
                content: none;
            }
            li{
                float: none;
                margin-bottom: 5px;

            }
            li.active a{
                text-decoration: underline;
                border: 1px solid #8d8f88;
            }
            li a{
                border: 1px solid #8d8f88;
                border-radius: 3px;
            }
        }

    }
    .sort_params {
        li{
            width: 100%;
            padding: 0;
            select{
                width: 100%;
            }
        }
    }
    .cart_total_price{
        margin-top: 30px;
    }
    .prod_info {
        display: block;

        .image_box {
            width: 100%;
            max-width: unset;
            margin-bottom: 15px;
            padding: 0;

            .image {
                height: unset;
                width: 100%;

                img {
                    height: auto;
                }
            }
        }

        .product_box {
            width: 100%;
        }
    }

    .itemsLabels {
        top: 9px;
        left: 1px;
    }

    .list-unstyled.postsList {
        display: block;

        li {
            width: 100%;
        }
    }

    .mobile-main-menu {
        right: unset;
        left: 0;
        width: 100%;
        max-width: 100%;
    }

    .g-recaptcha {
        &>div {
            width: auto !important;

            iframe {
                max-width: 100%;

                .rc-anchor-normal {
                    width: auto;

                    .rc-anchor-pt {
                        left: 50%;
                        transform: translateX(-50%);
                        right: unset;
                        margin: 0 auto;
                        width: 190px;
                    }
                }

                .rc-anchor-logo-portrait {
                    margin: 5px 0 4px 10px;
                }

                .rc-anchor-normal .rc-anchor-content {
                    width: auto;
                }
            }
        }

    }
}